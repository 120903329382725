import { Resource } from "@prisma/client";
import { FormikErrors, FormikState } from "formik";

export type User = {
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email: string;
  type: string; // TODO: make a Union type
  role: string; // TODO: make a Union type
  token?: string;
  fcmToken?: string;
};

export type Media = {
  type: "IMAGE" | "VIDEO";
  url: string;
  publicId: string;
  spark: Spark;
};

export type Spark = {
  id: string;
  notes: string;
  clusters: Cluster[];
  connections: Connection[];
  resource?: Resource;
  media?: Media;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  shared: boolean;
};

export type Cluster = {
  id: string;
  name: string;
  description: string;
  sparks?: Spark[];
  shared: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: User;
};

export type Connection = {
  id: string;
  sparks: Spark[];
  createdAt: Date;
  updatedAt: Date;
};

export enum AnalyticsEventName {
  ADD_NEW_SPARK = "ADD_NEW_SPARK",
  VIEW_SPARK_CLUSTER = "VIEW_SPARK_CLUSTER",
  VIEW_CONNECTED_SPARK = "VIEW_CONNECTED_SPARK",
  VIEW_SPARK_FROM_UPDATE = "VIEW_SPARK_FROM_UPDATE",
  VIEW_CLUSTER_FROM_UPDATE = "VIEW_CLUSTER_FROM_UPDATE",
  SWITCH_TO_USER_FOR_PROCESSING = "SWITCH_TO_USER_FOR_PROCESSING",
  REMOVE_SPARK_FROM_CLUSTER = "REMOVE_SPARK_FROM_CLUSTER",
  CREATE_CLUSTER = "CREATE_CLUSTER",
  DELETE_CLUSTER = "DELETE_CLUSTER",
  DELETE_SPARK = "DELETE_SPARK",
  EDIT_SPARK = "EDIT_SPARK",
  CLICK_LINK_TO_ORIGINAL_RESOURCE = "CLICK_LINK_TO_ORIGINAL_RESOURCE",
  EDIT_CLUSTER = "EDIT_CLUSTER",
  FOLLOW_CLUSTER = "FOLLOW_CLUSTER",
  UNFOLLOW_CLUSTER = "UNFOLLOW_CLUSTER",
  LOGOUT = "LOGOUT",
  CLICK_HELP = "CLICK_HELP",
  CLICK_YOUR_SUBSCRIPTION = "CLICK_YOUR_SUBSCRIPTION",
  CLICK_IOS_APP = "CLICK_IOS_APP",
  CLICK_BROWSER_EXTENSION = "CLICK_BROWSER_EXTENSION",
  CLICK_COMMUNITY = "CLICK_COMMUNITY",
  ADD_SPARK_TO_CLUSTER = "ADD_SPARK_TO_CLUSTER",
  ADD_CONNECTION = "ADD_CONNECTION",
  CHANGE_SPARK_SHARE_SETTING = "CHANGE_SPARK_SHARE_SETTING",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  REMOVE_CONNECTION = "REMOVE_CONNECTION",
  VIEW_CLUSTERS = "VIEW_CLUSTERS",
  VIEW_SPARKS = "VIEW_SPARKS",
  VIEW_UPDATES = "VIEW_UPDATES",
  VIEW_CLUSTER = "VIEW_CLUSTER",
  VIEW_SPARK = "VIEW_SPARK",
  GOOGLE_LOGIN = "GOOGLE_LOGIN",
  GOOGLE_SIGNUP = "GOOGLE_SIGNUP",
  MOBILE_LOGIN = "MOBILE_LOGIN",
  MOBILE_SIGNUP = "MOBILE_SIGNUP",
}

export type AnalyticsEventData = {};

// export type Spark = {
//   notes: string;
//   spark_id: string;
//   userId: string;
//   user: string;
//   connections: string[];
// };

// TODO:
export enum ResourceType {
  AUDIOBOOK = "AUDIOBOOK",
  BOOK = "BOOK",
}

// TODO:
export type Update = any;

type ListArgs = {
  offset?: number;
  limit?: number;
};

export type SparksFilters = {
  show?: string;
  state?: string;
  date?: string;
};

export type GetSparksArgs = ListArgs & {
  clusterId?: string;
  shared?: boolean;
  filters?: SparksFilters;
  query?: string;
};

export type GetSparksResponse = {
  sparks: Spark[];
  total: number;
};

export type ClustersFilters = {
  show?: string;
};

export type GetClustersArgs = ListArgs & {
  shared?: boolean;
  filters?: ClustersFilters;
  query?: string;
};

export type GetClustersResponse = {
  clusters: Cluster[];
  total: number;
};

export type GetSparkArgs = {
  shared?: boolean;
};

export type GetClusterArgs = {
  shared?: boolean;
};

export type GetUpdatesArgs = ListArgs;

export type GetUpdatesResponse = {
  updates: Update[];
  total: number;
};

export type SearchArgs = ListArgs & {
  query?: string;
};

export type EditSparkForm = {
  notes: string;
  resource?: any; // TODO: type
  files?: File[];
  clusterId?: string;
  resetForm: (
    nextState?:
      | Partial<
          FormikState<{
            notes: string;
          }>
        >
      | undefined,
  ) => void;
  setErrors: (
    errors: FormikErrors<{
      notes: string;
    }>,
  ) => void;
};

export type NavigationItem = {
  id: string;
  type: "link" | "component";
  href?: string;
  component?: React.ReactNode;
  text: string;
  icon?: React.ReactNode;
  visible: boolean;
  onClick?: () => void;
};

export type AppState = {
  isAppSession: boolean;
  appUser: User | null;
  fcmToken: string | null;
  eventListenerIsSet: boolean;
  isLoggingOut: boolean;
};

export type FormatResourceInfoArgs = {
  resource: any;
  createdAt?: string;
  user?: any;
  spark?: any;
  isProcessingUser?: boolean;
};

export type SparkGraphData = {
  spark: Spark & {
    connected_spark_idx: string[];
  };
  sparks: Spark &
    {
      spark_id: string;
      connections: string[];
    }[];
};

// TODO: might be a mismatch with prisma/types
export type OgData = {
  title: string;
  type?: string;
  image: any; // TODO: proper type
  url?: string;
  audio?: any;
  description?: string;
  determiner?: string;
  locale?: string;
  localeAlternate?: string;
  siteName?: string;
  video?: any;
  article?: any;
  book?: any;
  profile?: any;
};

export type OpenGraphData = {
  [key: string]: any;
};

export type Metadata = {
  title?: string;
  description?: string;
  url?: string;
  author?: string;
  platform?: string;
  chapter?: string;
  ogData?: OpenGraphData;
};

export type AggregationPaginationStage = {
  $skip?: number;
  $limit?: number;
};

export type RelationType = "clusters" | "connections" | "sparks";
